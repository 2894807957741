.opponents {
    .background {
        background-image: url('../Assets/test-background.png');
        background-size: cover;
        background-position: center;
        width: calc(100% + 32vh - var(--playmat-border) * 2) !important;
        margin: var(--playmat-border);
        margin-top: 0;
        height: calc(100% - var(--playmat-border)) !important;
        border-radius: var(--playmat-border-radius);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .opponent-section>div {
        &:not(.background) {
            transform: rotate(180deg);
        }

        &.background {
            transform: translateX(0%);
        }
    }

    .opponent-section.zoomed {
        z-index: 1;
    }

    &.total-2 {
        .opponent-section.unzoomed:nth-child(1)>div {
            &:not(.background) {
                transform: translateX(-25.3%) translateY(-2%) rotate(180deg) scale(0.49);
            }

            &.background {
                transform: translateX(0%);
                width: calc(50% - var(--playmat-border) * 2) !important;
            }
        }

        .opponent-section.unzoomed:nth-child(2)>div {
            &:not(.background) {
                transform: translateX(25.3%) translateY(-2%) rotate(180deg) scale(0.49);
            }

            &.background {
                margin-left: calc(50% + var(--playmat-border));
                width: calc(50% + 32vh - var(--playmat-border) * 2) !important;
            }
        }
    }

    &.total-3 {
        .opponent-section.unzoomed:nth-child(1)>div {
            &:not(.background) {
                transform: translateX(-35%) translateY(-1%) rotate(125deg) scale(0.35);

                .discard {
                    right: 17vh;
                }

                .deck {
                    right: calc(var(--card-width) + 18vh);
                }
            }

            &.background {
                transform: translateX(0%);
                width: calc(32% - var(--playmat-border) * 2) !important;
            }
        }


        .opponent-section.unzoomed:nth-child(2)>div {
            &:not(.background) {
                transform: translateY(-10%) rotate(180deg) scale(0.35);
            }

            &.background {
                margin-left: calc(32% + var(--playmat-border));
                width: calc(36% - var(--playmat-border) * 2) !important;
            }
        }

        .opponent-section.unzoomed:nth-child(3)>div {
            &:not(.background) {
                transform: translateX(34%) translateY(-1%) rotate(-125deg) scale(0.35);

                .discard {
                    right: 17vh;
                }

                .deck {
                    right: calc(var(--card-width) + 18vh);
                }
            }

            &.background {
                margin-left: calc(68% + var(--playmat-border));
                width: calc(32% + 32vh - var(--playmat-border) * 2) !important;
            }
        }
    }



    // Stack position
    .opponent-section>div {
        .game-card.Stack {
            top: -45% !important;
            left: -15% !important;
            //scale: 0.5;
        }
    }

    &.total-2 {
        .opponent-section.unzoomed:nth-child(1)>div {
            .game-card.Stack {
                top: -100% !important;
                left: -130% !important;
            }
        }

        .opponent-section.unzoomed:nth-child(2)>div {
            .game-card.Stack {
                top: -100% !important;
                left: -25% !important;
            }
        }
    }

    &.total-3 {
        .opponent-section.unzoomed:nth-child(1)>div {
            .game-card.Stack {
                top: -750% !important;
                left: -110% !important;
                rotate: 45deg;
            }
        }


        .opponent-section.unzoomed:nth-child(2)>div {
            .game-card.Stack {
                top: -220% !important;
                left: -125% !important;
            }
        }

        .opponent-section.unzoomed:nth-child(3)>div {
            .game-card.Stack {
                top: 80% !important;
                left: -40% !important;
                rotate: -45deg;
            }
        }
    }

    &.total-2 {
        .opponent-section.unzoomed {
            .section-card-count {
                font-size: 4vh;
            }

            .ressource-count {
                font-size: 4vh;
                z-index: 1;
            }
        }
    }

    &.total-3 {
        .opponent-section.unzoomed {
            .section-card-count {
                font-size: 7vh;
            }

            .ressource-count {
                font-size: 7vh;
                z-index: 1;
            }

            &:nth-child(1) {
                .section-card-count {
                    rotate: 55deg;
                }

                .ressource-count {
                    rotate: -125deg;
                }
            }

            &:nth-child(3) {
                .section-card-count {
                    rotate: -55deg;
                }

                .ressource-count {
                    rotate: 125deg;
                }
            }
        }
    }
}

.opponent-section {
    /*clip-path: inset(0 0 0 0);*/

    padding: 1vh;
    padding-top: 0;
    padding-bottom: calc(2vh - var(--playmat-border));

    .board-section,
    .hand,
    .discard {
        background-color: transparent !important;

        .section-title {
            color: transparent !important;
        }
    }


    .visible-cards {
        // EXCUSE ME WTF ?
        transform: translateX(calc((4rem + 0.75vh) * -1));
    }

    .dropdown {
        pointer-events: none;
        display: none;
    }

    .deck {
        pointer-events: none;
    }

    &,
    & * {
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
}