.game {
    --card-height: 15vh;
    --card-width: calc(var(--card-height) * 0.7);
    --playmat-border: 0.25vh;
    --playmat-border-radius: 1.5vh;
    --section-background-rgb: 0, 0, 0;
    height: 100vh;
    overflow: hidden;

    .mouse-dropable-section {
        //border: 2px solid white;
        border-radius: 1vh;

        //background-color: rgba(255, 255, 255, 0.143);
        .section-title {
            color: transparent;
            transition: color 0.15s;
            font-size: 1rem;
        }

        &.bound {
            background-color: rgba(var(--section-background-rgb), 0.65) !important;
        }
    }

    &:has(.dragged) {
        .mouse-dropable-section {
            background-color: rgba(var(--section-background-rgb), 0.25);

            .section-title {
                color: white;
            }
        }
    }

    .focused-card {
        z-index: 9999999;
        pointer-events: none;
        width: calc(var(--card-width) * 3) !important;
        height: calc(var(--card-height) * 3) !important;

        img {
            border-radius: 1.5vh;
            box-shadow: 0 0 2vh rgba(0, 0, 0, 0.704);
        }

        &.horizontal {
            rotate: 90deg;
            transform-origin: top left;

            img {
                translate: 0 -100%;
            }

            .card-counters {
                translate: 20% -140%;
            }

            .card-notes {
                rotate: -90deg;
                transform-origin: bottom left;
                translate: -10% -10%;
                top: auto !important;
                bottom: 100% !important;
                left: 100% !important;
            }
        }

        .card-notes {
            width: 15vh;
            transform: translateX(-100%);
            translate: 10% -10%;
            border-radius: var(--bs-border-radius);
            overflow: hidden;
            box-shadow: 0 0 1vh rgba(0, 0, 0, 0.528);
            pointer-events: none;

            textarea {
                background-color: transparent;
                resize: none;
            }

            &.empty {
                display: none;
            }
        }
    }

    .ping {
        width: 12vh;
        height: 12vh;
        border-radius: 50%;
        animation: ping-animation 1s forwards;
        transform-origin: top left;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            background-color: white;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            animation: ping-content-animation 1s forwards;
            transform-origin: top left;

            @keyframes ping-content-animation {
                0% {
                    background-color: rgba(var(--main-color-rgb), 0.8);
                    scale: 0;
                }

                80% {
                    background-color: rgba(var(--main-color-rgb), 0.8);

                }

                100% {
                    background-color: rgba(var(--main-color-rgb), 0);
                    scale: 0.8;
                }
            }
        }

        @keyframes ping-animation {
            0% {
                scale: 0;
                background-color: rgba(var(--main-color-rgb), 0.8);
            }

            50% {
                background-color: rgba(var(--main-color-rgb), 0.8);
            }

            100% {
                scale: 1;
                background-color: rgba(var(--main-color-rgb), 0);
            }
        }
    }

    .current-player {
        .background {
            --border-size: 0.25vh;
            outline: 0.25vh solid var(--main-color);
        }
    }

    .card-link {
        filter: drop-shadow(0 0 1vh black);
    }

    .left-bar {
        background-color: var(--bs-body-bg-secondary);
        padding: 1vh 0;
        z-index: 1000;

        button {
            margin: 0.5vh 0;
        }

        .tooltip-overlay {

            &,
            & * {
                user-drag: none;
                -webkit-user-drag: none;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }

            &:hover .overlay {
                display: flex;
            }

            .overlay {
                right: -1vh;
                top: 50%;
                translate: 100% -50%;
                width: 100vw;
                pointer-events: none;
                display: none;

                p {
                    padding: 1vh;
                    background-color: var(--bs-body-bg);
                    border-radius: var(--bs-border-radius);
                }
            }
        }
    }

    .presented-toggle-button {
        display: none;
    }

    &:has(.player-section .presented) .presented-toggle-button {
        display: block;
    }

    .visible-cards {
        //filter: drop-shadow(0 0 0.5vh rgba(0, 0, 0, 0.5));
    }

    .new-turn-message {
        animation: message-anim 3s forwards;
        background-color: var(--bs-body-bg-secondary);
        width: 30vh;
        left: 50%;
        border-radius: var(--bs-border-radius);
        padding: 1vh;
        box-shadow: 0 0 2vh rgba(0, 0, 0, 0.515);

        &.your-turn {
            background-color: var(--main-color) !important;
        }

        p {
            font-weight: 600;
        }

        @keyframes message-anim {

            0%,
            100% {
                opacity: 0;
                margin-top: -10vh;

            }

            50% {
                //box-shadow: 0 0 2vh var(--main-color);
            }

            10%,
            90% {
                opacity: 1;
                margin-top: 8vh;
                //box-shadow: 0 0 2vh transparent;
            }
        }
    }

    &.hide-play-area {
        .board-section .blurred-background {
            display: none;
        }
    }

    &.shared-turn .current-player .background {
        outline: none;
    }

    transform-style: preserve-3d;
    perspective: 2000px;
}

.action-mode.offcanvas {
    --bs-offcanvas-bg: transparent;
    --bs-offcanvas-border-width: 0;
}
/*
.token {
    --token-size: 10vh;
    width: var(--token-size);
    height: var(--token-size);
    transition: all 0.3s;

    img {
        filter: drop-shadow(0 0 0.5vh rgba(0, 0, 0, 0.5));
    }
}*/