body {
  --main-color-rgb: 233, 69, 96;
  --main-color: rgb(var(--main-color-rgb));
  --test: #e94560;
  --app-bg: #1a1a22;
  --bs-body-bg: #18293d;
  --bs-body-bg-secondary: #191e2b;
  --bs-body-color: rgb(254, 254, 254);
  --bs-border-color: transparent;

  --bs-secondary-bg: var(--bs-body-bg);
  --bs-tertiary-bg: var(--bs-body-bg-secondary);

  --bs-body-color-rgb: 252, 252, 252;

  .accordion-item {
    --bs-accordion-bg: var(--bs-body-bg) !important;
  }


  .dropdown-item {
    --bs-dropdown-link-hover-bg: var(--bs-body-bg-secondary);
    --bs-dropdown-link-active-bg: var(--main-color);

    &.selected {
      background-color: var(--main-color);
    }
  }

  .alert-secondary {
    --bs-alert-color: white;
    --bs-alert-bg: var(--app-bg);
    --bs-alert-border-color: var(--app-bg);
    --bs-alert-link-color: var(--main-color);
  }

  .offcanvas {
    --bs-offcanvas-bg: var(--app-bg);
  }

  .modal {
    --bs-modal-bg: var(--app-bg);
  }

  .btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: #fff;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--main-color);
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
  }

  .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--bs-body-bg);
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--main-color);
    --bs-btn-hover-border-color: var(--main-color);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--main-color);
    --bs-btn-active-border-color: var(--main-color);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
  }

  .pagination {
    --bs-link-color: var(--main-color);
    --bs-pagination-active-bg: var(--main-color);
    --bs-pagination-active-border-color: var(--main-color);
    --bs-link-hover-color: var(--main-color);
    --bs-pagination-disabled-color: white;
  }

  input::placeholder,
  textarea::placeholder {
    color: gray !important;
    opacity: 1;
    /* Firefox */
    text-transform: capitalize;
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: gray;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--app-bg);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fullscreen-modal.modal {
  --bs-modal-width: 80vw;

  .modal-content {
    min-height: 80vh;
  }
}

.box-container {
  background-color: var(--bs-body-bg-secondary);
  padding: 1vh;
  margin: 1vh;
  border-radius: var(--bs-border-radius);
  display: flex;
  flex-direction: row;

  h5 {
    margin-left: 0.5rem !important;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &>* {
    margin: 0 0.5rem;
  }

  &>*:first-child {
    margin-left: 0;
  }

  &>*:last-child {
    margin-right: 0;
  }

  .brighter {
    background-color: var(--bs-body-bg);
    border-radius: var(--bs-border-radius);
  }
}

button:has(img),
.btn:has(img) {
  aspect-ratio: 1;

  img {
    display: inline-block;
    width: var(--bs-body-font-size);
    height: var(--bs-body-font-size);
  }
}

p img {
  height: var(--bs-body-font-size);
  width: var(--bs-body-font-size);
  margin: 0 0.5vh;
}

.tooltip .tooltip-inner {
  color: white;
  padding: 1vh;
}

h3 {
  font-weight: 700 !important;
}

h4,
.h4 {
  font-weight: 700 !important;
}

h5 {
  font-weight: 600 !important;
}

@media (orientation: landscape) {
  .mobile-warning {
    display: none;
  }
}

@media (orientation: portrait) {
  .app-content {
    display: none;
  }
}


@media (min-width: 2300px) {
  .container {
    max-width: 1800px !important;
  }
}

@media (min-width: 3000px) {
  .container {
    max-width: 2200px !important;
  }
}