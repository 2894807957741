.layout-board-section {
    width: 100%;
    justify-content: space-between;

    &.flex-column, &.flex-row-colum {
        
    }

}

.board-section {
    transition: background-color 0.1s;

    &:has(.buttons :hover) {
        background-color: rgba(var(--section-background-rgb), 0.122);
    }

    .blurred-background {
        --blur: 0px;
        background-color: rgba(var(--section-background-rgb), 0.2) !important;
        backdrop-filter: blur(var(--blur));
        -webkit-backdrop-filter: blur(var(--blur));
        outline: 0.1vh solid rgba(var(--section-background-rgb), 0.3);
        border-radius: 1vh;
    }

    border-radius: 1vh;

    /*
    KEEPING IT FOR EXALTS TABLE
    //background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.388) 90%);
    .section-top-visual {
        --border-color: rgba(255, 255, 255, 0.9);
        --border-width: 0.25vh;
        width: 100%;
        height: 5vh;
        border-right: var(--border-width) solid var(--border-color);
        border-left: var(--border-width) solid var(--border-color);
        border-top: var(--border-width) solid var(--border-color);
        border-top-right-radius: 1vh;
        border-top-left-radius: 1vh;
    }*/
}

.board-section, .hand-board-section {
    &.alignment-Start, &.alignment-Center {
        .buttons {
            left: 0 !important;
            right: auto !important;
        }
    }

    .buttons {
        top: -2.5vh;
        right: 0vh;

        .text {
            font-weight: 700;
            filter: drop-shadow(0 0 1vh black);
            margin-top: 0.5vh;
            pointer-events: none;
        }

        &>button {
            display: none;
        }

        .dropdown:not(.show)>button {
            scale: 0.4;

            &::after {
                scale: 2;
            }
        }

        &:hover {
            
            .dropdown>button{
                scale: 1;

                &::after {
                    scale: 1;
                }
            }

            z-index: 1;

            &.button-tap .tap-all,
            &.button-tap .untap-all,
            &.button-hand .hand-all,
            &.button-discard .discard-all,
            &.button-reveal .reveal-all,
            &.button-reveal .hide-all,
            &.button-tap-one .tap-one,
            &.button-tap-one .untap-one {
                display: flex;
            }

            .toggle {
                z-index: -1;
            }
        }
    }
}

.opponent-section {

    .buttons button,
    .section-top-visual {
        display: none !important;
    }

    .board-section .buttons .text {
        rotate: 180deg;
        top: auto !important;
        bottom: 0;
    }
}