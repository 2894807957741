.game-build {
    .form-group {
        margin-top: 2rem;
        margin-bottom: 3rem;
        padding-left: 3rem;
        border-left: 1px solid white;
    }

    .form-line {
        display: flex;
        width: 100%;
        white-space: pre;

        label {
            margin-right: 1.5rem;
        }
    }
}