.fullscreen-decklist {
    .col-2 {
        width: 14.28%;
    }

    h5 {
        font-weight: 700;
    }

    .fullscreen-card {
        img {
            border-radius: 1vh;
            box-shadow: 0 0 1vh rgba(0, 0, 0, 0.534);
        }

        .count {
            background-color: var(--bs-body-bg);
            padding: 0.1rem 0.7rem;
            border-top-left-radius: 1vh;
            border-bottom-right-radius: 1vh;

            &>* {
                font-weight: 700;
                font-size: 1.5rem;
            }
        }
    }
}