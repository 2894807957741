.deck-list-panel {
    --border-radius: var(--bs-border-radius);
    --border-color: rgba(255, 255, 255, 0.107);
    .accordion {
        --bs-accordion-body-padding-x: 0.3rem !important;
        --bs-accordion-body-padding-y: 0.3rem !important;
        --bs-accordion-btn-padding-x: 0.7rem !important;
        --bs-accordion-btn-padding-y: 0.7rem !important;
        --bs-accordion-active-bg: transparent;
        --bs-accordion-border-radius: var(--border-radius);
        --bs-accordion-border-width: 0;
        --bs-accordion-active-color: white;

        .accordion-collapse {
            max-height: fit-content !important;
        }
    }

    .accordion-button::after {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }
    
    background-color: var(--bs-body-bg-secondary);
    padding: 1.2rem;
    border-radius: var(--border-radius);

    --size: calc(var(--bs-body-font-size) * 2);

    .accordion-item, .total-cards {
        margin-bottom: 1rem;
        border: 1px solid var(--border-color);
        border-radius: var(--bs-accordion-border-radius);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .card-count {
        font-weight: bold;
        height: var(--size);
        width: var(--size);
        min-width: var(--size);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        border-radius: var(--bs-accordion-border-radius);
        background-color: var(--bs-body-bg);
        margin-right: 1rem;

        &.type {
            background-color: var(--main-color);
            color: white;
        }
    }

    .card-element {
        background-color: var(--bs-body-bg-secondary);
        border-radius: var(--bs-accordion-border-radius);
        margin: 0.5rem 0;

        .image-background {
            opacity: 0;
            scale: 2;
            &.horizontal {
                rotate: 90deg;
            }
        }

        .background-cover {
            background-color: var(--bs-body-bg-secondary);
            opacity: 0.4;
        }

        .content {
            //backdrop-filter: blur(10px);
            padding: 0.5rem;
        }

        button {
            height: var(--size);
            width: var(--size);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border-radius: var(--bs-accordion-border-radius);
        }
    }

    .total-cards {
        border: 1px solid var(--border-color);
        padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
        border-radius: var(--bs-accordion-border-radius);
    }
}