.deck {
    bottom: calc(9vh - var(--card-height));
    right: calc(var(--card-width) + 1vh);
    width: var(--card-width);
    height: var(--card-height);
    border-radius: 1vh;

    &.empty>img {
        opacity: 0;
    }

    .top-card {
        &, & img {
            border-radius: 1vh;
        }

        &.hidden {
            opacity: 0;
        }
    }

    .interaction-zone {
        z-index: 999;
        transform: translateZ(1);

        .click-area {
            background-color: transparent;
            cursor: pointer;
            opacity: 0.000001;
            border-radius: 1vh;
            overflow: hidden;

            .deck-background {
                opacity: 1;
                background-color: rgba(0, 0, 0, 0.6);
            }

            p {
                margin-top: 3.5vh;
                font-weight: 700;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
    .deck-pile {
        perspective: 800px;

        &>img {
            border-radius: 1vh;
            transform-style: preserve-3d;
        }

        &>img:first-child {
            box-shadow: 0 0 2vh black;
        }
    }
}

.modal-game-panel {
    img.modal-panel-card {
        border-radius: 1.8vh;
        box-shadow: 0 0 1vh black;
    }

    .modal-panel-card {
        margin: 0 3vh;
        height: 50vh;

        img {
            border-radius: 1.8vh;
            box-shadow: 0 0 1vh black;
        }

        .box-container {
            width: calc(100% - 6vh);
        }
        
        &:has(img:hover) .box-container, 
        .box-container:hover {
            display: flex !important;
        }
    }
}

.section-card-count {
    font-weight: 500;
    font-size: 3vh;
    filter: drop-shadow(0 0 1vh black);
}

.opponent-section {
    .section-card-count {
       scale: -1 -1;
       transform-origin: top left;
    }

    .deck .click-area {
        opacity: 0 !important;
        cursor: auto;
    }
}