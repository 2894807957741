.footer {
    background: var(--bs-body-bg-secondary);
}

.preferences {
    h5 {
        margin-bottom: 1rem;
    }

    .preference-default-image {
        height: 7rem;
        object-fit: cover;
        cursor: pointer;
        border-radius: var(--bs-border-radius);
        margin: 0.5rem;
    
        &.selected {
    
        }
    
        &:hover {
            opacity: 0.7;
        }
    }
}

.blurred-background {
    .blur {
        backdrop-filter: blur(20px);
    }

    .background {
        background-color: var(--bs-body-bg-secondary);
        opacity: 0.7;
    }
}