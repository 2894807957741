.top-section {
    height: 28vh;

    .background {
        background: linear-gradient(0deg, var(--app-bg) 0%, rgba(10, 10, 10, 0) 30%);
        height: 35vh !important;
    }

    .background-color {
        height: 35vh !important;
    }

    .background-image {
        height: 35vh !important;
    }
}

.decks .box-container {
    //box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
}

.deck-background {
    height: 10vh;
    overflow: hidden;
    border-radius: var(--bs-border-radius);
    //mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 35%);
    opacity: 0.5;
    img {
        height: 100%;
        width: 100%;
        object-position: 50% 15%;
        scale: 1.6;
        object-fit: cover;
        translate: 10%;
        filter: blur(40px);
    }
}