.game-card {
    --zoomed-scale: 1;
    --card-flip-duration: 0.3s;
    --card-move-duration: 0.4s;
    --card-tap-duration: 0.2s;
    width: var(--card-width);
    height: var(--card-height);
    position: absolute;
    cursor: pointer;
    z-index: auto !important;
    pointer-events: none;

    .card-images {
        pointer-events: all;
    }

    &.tapped {
        &:not(.dragged):not(.Stack) {
            .card-images-and-wrapper {
                rotate: 45deg !important;
                translate: 0% 0 !important;
            }

            // A voir le translate si on garde ou pas
            &.opponent-card {
                .card-images-and-wrapper {
                    translate: 0% 0 !important;
                }

                &.card-horizontal {
                    &.tapped .card-images-and-wrapper {
                        translate: 0% 0 !important;
                    }
                    .card-images-and-wrapper {
                        translate: 20% 0 !important;
                    }
                }
            }

            .quick-actions {
                rotate: -45deg !important;
            }

            .link-button {
                rotate: 0deg !important;
                margin-top: 0 !important;
                margin-left: 0 !important;
                transform: rotate(-45deg) translateX(calc(100% - 0.2rem));
                translate: 0% 0% !important;
            }
        }
    }

    // CARD HIDDEN

    .card-back,
    .card-front,
    .card-front-and-back,
    .card-hidden-back {
        transition: transform var(--card-flip-duration);
    }

    &.card-hidden-yes {
        .card-front-and-back {
            transform: rotateY(90deg);
            transition-delay: 0s;
        }

        .card-hidden-back {
            transform: rotateY(0deg);
            transition-delay: var(--card-flip-duration);
        }
    }

    .card-front-and-back {
        transform: rotateY(0deg);
        transition-delay: var(--card-flip-duration);
    }

    .card-hidden-back {
        transform: rotateY(-90deg);
        transition-delay: 0s;
    }

    // CARD FLIPPED

    &.card-flipped {
        .card-front {
            transform: rotateY(90deg);
            transition-delay: 0s;
        }

        .card-back {
            transform: rotateY(0deg);
            transition-delay: var(--card-flip-duration);
        }
    }

    .card-front {
        transform: rotateY(0deg);
        transition-delay: var(--card-flip-duration);
    }

    .card-back {
        transform: rotateY(-90deg);
        transition-delay: 0s;
    }

    // CARD HORIZONTAL

    &.card-horizontal {
        &:not(.dragged):not(.Stack) {
            &.tapped {
                .card-images-and-wrapper {
                   translate: 0 !important;
                }
            }
            .card-images-and-wrapper {
                rotate: 90deg;
                translate: 21% !important;
            }

            .wrapper .quick-actions {
                rotate: -90deg;
            }

            &:not(.tapped) {
                .quick-actions {
                    top: auto !important;
                    bottom: 0;
                }

                .link-button {
                    bottom: 0;
                    margin-bottom: 1rem;
                    translate: 0% calc(-100% + 1.1rem);
                }
            }

            .link-button {
                display: none;
                margin-top: -1rem;
                margin-left: 0;
                rotate: -90deg;
                translate: 0% calc(100% + 0.8rem);
                pointer-events: all;
            }
        }
    }



    .card-images {
        scale: 0.95;

        img {
            //transition: box-shadow 0.3s; A REFAIRE
            box-shadow: 0 0 1vh 0.5vh rgba(0, 0, 0, 0.195);
            pointer-events: none;
        }
    }

    &.card-horizontal {
        .card-click-info p {
            rotate: -90deg;
            transform-origin: 50% 50%;
        }
    }

    .card-click-info {
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.15s;

        p {
            font-weight: 700;
            margin: 0;
        }
    }

    &:not(.dragged) {
        transition: left var(--card-move-duration),
            top var(--card-move-duration),
            opacity var(--card-move-duration),
            width var(--card-move-duration),
            height var(--card-move-duration),
            margin var(--card-flip-duration),
            translate var(--card-move-duration);

        .card-images-and-wrapper {
            transition: all var(--card-tap-duration);
        }

        &:not(.Stack).hover:not(&:has(.card-counters:hover)):not(&:has(.token-count:hover)),
        &:not(.Stack).hover:not(&:has(.card-counters:hover)):not(&:has(.token-count:hover)) {
            .card-click-info {
                opacity: 1 !important;
            }
        }
    }

    &.dragged {
        width: calc(var(--card-width) * 2) !important;
        height: calc(var(--card-height) * 2) !important;
        translate: calc(var(--card-width) * -1) calc(var(--card-height) * -1);
        //translate: -50% -50%;
        transition: transform 0.2s;
        filter: drop-shadow(0 -3vh 8vh rgba(0, 0, 0, 0.3));

        .card-images {
            rotate: 0deg !important;
            opacity: 1 !important;

            img {
                border-radius: 1.2vh !important;
            }

            .card-front {
                box-shadow: 0 0 2vh 1vh rgba(0, 0, 0, 0.195);
            }
        }

        z-index: 999999 !important;

        .quick-actions,
        .card-counters {
            display: none !important;
        }

        .token-count {
            pointer-events: none !important;
        }
    }

    &,
    &>img {
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    &.Stack:not(.opponent-card) .card-images {
        box-shadow: 0 0 0.5vh 0.25vh var(--main-color);
    }

    &.Stack .card-images-and-wrapper {
        opacity: 0;
    }

    &.Stack.reversed-index-0 {
        z-index: 1000 !important;

        .card-images-and-wrapper {
            opacity: 1;
            rotate: 0deg;
        }
    }

    &.Stack.reversed-index-1 .card-images-and-wrapper {
        opacity: 1;
        rotate: -5deg;
        translate: -2vh;
    }

    &.Stack.reversed-index-2 .card-images-and-wrapper {
        opacity: 0.8;
        rotate: -10deg;
        translate: -4vh;
    }

    &.Stack.reversed-index-3 .card-images-and-wrapper {
        rotate: -15deg;
        opacity: 0.6;
        translate: -6vh;
    }

    &.Stack.reversed-index-4 .card-images-and-wrapper {
        rotate: -20deg;
        opacity: 0;
        translate: -8vh;
    }



    .wrapper {
        z-index: 200;
        opacity: 1 !important;

        .quick-actions {
            transition: rotate 0.3s;
            //transform: translateZ(100);
            pointer-events: all !important;
            display: none;
        }

        .quick-actions.show {
            display: flex;
        }

        .link-button {
            display: none;
            margin-left: -1rem;
            translate: calc(100% + 0.8rem);
            pointer-events: all;
            // A FAIRE MIEUX
            padding: var(--bs-btn-padding-x);

            img {
                width: 0.7rem;
                height: 0.7rem;
            }
        }
    }


    &:hover {
        .wrapper {
            .quick-actions {
                display: flex;
            }

            &:has(.quick-actions:hover) .link-button,
            .link-button:hover {
                display: flex;
            }
        }
    }

    &.Hand {
        .card-images {
            scale: 1;
        }

        .card-images img {
            //box-shadow: 0 0 1vh rgba(0, 0, 0, 0.8) !important;
        }
    }

    &.presented:not(.dragged) {
        margin-top: -2vh;

        &.tapped {
            margin-top: -0.5vh;
        }

        .card-images {
            box-shadow: 0 0 0 0.2vh yellow;
            z-index: 100 !important;
        }

        .presented-image {
            z-index: 500;
            top: 2.5vh;
            height: 11vh;
            box-shadow: none;
            filter: brightness(0) saturate(100%) invert(86%) sepia(70%) saturate(555%) hue-rotate(9deg) brightness(103%) contrast(91%) drop-shadow(0 0 1vh black);
        }

        &.card-horizontal {
            .presented-image {
                translate: 29%;
            }
        }
    }

    &.Discard,
    &.Discard * {
        pointer-events: none !important;
        box-shadow: none !important;
    }

    &.Exile, &.ExileHidden {
        opacity: 0;
    }

    .token-count {
        background-color: transparent;
        font-weight: 700;
        font-size: 3vh;
        text-align: end;
        text-shadow: 0 0 0.75vh black;
    }

    &.trigger-effect {
        .card-images img {
            filter: grayscale(100%);
        }

        .quick-actions {
            display: hidden !important;
        }
    }

    &.Hand.card-hidden-no .card-revealed-indicator {
        height: 1.8rem;
        margin: 0.2rem;
        display: block !important;
        box-shadow: none;
        filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.8));
    }

    .sleeve {
        display: none !important;
        box-shadow: 0 0 0.5vh black;
        background-color: rgb(36, 127, 40);
        scale: 1.035;
    }

    &.Stack:not(.dragged) {
        .sleeve {
            opacity: 0;
        }
    }
}

.card-counters {
    .counter {
        --counter-height: 4vh;
        --counter-width: 7vh;
        width: var(--counter-width);
        height: var(--counter-height);
        background-color: var(--bs-body-bg);
        border-radius: var(--bs-border-radius);

        input {
            width: 100% !important;
            background-color: transparent !important;
            pointer-events: all;
            color: white;
            font-size: calc(var(--counter-height) * 0.4);
            font-weight: 800;
            padding-right: 0.5vh;
        }

        &.secondary {
            background-color: var(--main-color);
            margin-top: 4%;
        }
    }

    &.horizontal {
        transform-origin: top left;
        transform: rotate(-90deg) translateX(-100%);
    }
}

.player-section {
    .game-card {
        &.mana-highlight {
            .card-images {
                box-shadow: 0 0 0.35vh 0.35vh var(--main-color);
                //scale: 0.8;
                //rotate: 20deg;
            }
        }

        &.Hand {
            &:hover:not(.dragged) {
                margin-top: -4vh;
            }
        }
    }


}

.opponent-section {
    .game-card.Stack {
        opacity: 0;

        &,
        & * {
            pointer-events: none !important;
        }
    }

    .game-card .card-images-and-wrapper img {
        scale: -1 -1;
    }

    .game-card .card-images-and-wrapper img.card-hidden-back {
        scale: 1 1;
    }

    .game-card {
        .presented-image {
            scale: 1 1 !important;
        }

        .card-counters .counter {
            scale: -1 -1;
        }

        .card-click-info p {
            scale: -1 -1;
        }
    }

    .token-count {
        bottom: auto !important;
        top: 0 !important;
        scale: -1 -1;
        pointer-events: none;
    }
}

.quick-actions .submenu button {
    width: 100%;
    text-align: start;
}