.player-counters-wrapper {
    margin: 0.25rem;

    &.opponent {
        .background,
        .blur {
            border-radius: var(--bs-border-radius) !important;
        }
    }

    .player-counters {
        width: 18vh;
        height: 4.5vh;
        background-color: var(--bs-body-bg-secondary);
        border-radius: var(--bs-border-radius);
        box-shadow: 0 0 2vh 1vh rgba(0, 0, 0, 0.166);

        img {
            object-fit: cover;
            border-top-left-radius: var(--bs-border-radius);
            border-bottom-left-radius: var(--bs-border-radius);
        }

        .form-control {
            font-size: 2vh;
            padding-right: 0.25vh;
            font-weight: 500;
            text-align: center;
        }
    }

    .background {
        background-color: var(--bs-body-bg-secondary);
        opacity: 0.8;
    }

    .background,
    .blur {
        border-radius: var(--bs-border-radius);
    }

    &:hover .notes.opponent textarea {
        pointer-events: auto;
        display: block;
    }

    .notes {
        translate: 0 -99%;
        border-radius: var(--bs-border-radius);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        overflow: hidden;

        &.opponent {
            translate: -100% 0;
            border-radius: var(--bs-border-radius);

            p {
                display: none;
            }

            &.empty {
                display: none !important;
            }
        }

        .background {
            background-color: var(--bs-body-bg);
        }

        .blur,
        .background {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        textarea {
            background-color: transparent;
            resize: none;
            display: none;
        }

        p {
            display: none;
            color: gray;
        }

        &:hover {
            textarea {
                pointer-events: auto;
                display: block;
            }

            p {
                display: none;
            }
        }
    }

    &:hover {
        p {
            display: block;
        }

        .background,
        .blur {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .tokens {
        height: 4.5vh;

        .token {
            .wrapper {
                aspect-ratio: 1 !important;

                &:hover img {
                    scale: 2.5;
                    z-index: 1;
                }

                img {
                    aspect-ratio: 1 !important;
                    max-height: 100%;
                    max-width: 100%;
                    filter: drop-shadow(0 0 0.5vh black);
                    pointer-events: none;
                }
            }
        }

        .toggle-tokens .dropdown-toggle {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.3vh;
            width: 3.5vh;

            &:after {
                display: none !important;
            }
        }
    }

    .blur {
        backdrop-filter: blur(20px);
    }
}

.opponent-counters,
.opponent-section {
    input[type="number"] {
        -moz-appearance: textfield;
    }

    input[type="number"]:hover,
    input[type="number"]:focus {
        -moz-appearance: number-input;
    }

    &:hover {
        .player-menu {
            display: flex !important;
        }
    }
}