.player-section {
    /*clip-path: inset(0 0 0 0);*/

    .background {
        //background-image: url('../Assets/test-background.png');
        background-size: cover;
        background-position: center;
        width: calc(100% + 32vh - var(--playmat-border) * 2) !important;
        margin: var(--playmat-border);
        margin-bottom: 0;
        height: calc(100% - var(--playmat-border)) !important;
        border-radius: var(--playmat-border-radius);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    padding: 1vh;
    padding-bottom: 0;
    padding-top: calc(2vh - var(--playmat-border));

    &,
    & * {
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
}

.mulligan-sections {
    height: 50vh;
    overflow: hidden;

    .wrapper {
        padding: 0;
    }

    .mulligan-section:first-child {
        img {
            transform-origin: top;
        }

        .wrapper.selected {
            /*height: calc(100% - 10vh) !important;
            margin-top: auto;*/
            margin-top: 40vh;
        }
    }

    .mulligan-section:last-child {
        img {
            transform-origin: bottom;
        }

        .wrapper.selected {
           /* height: calc(100% - 10vh) !important;
            margin-bottom: auto;*/
            margin-top: 30vh;
        }
    }

    .mulligan-section {
        height: 40vh;

        .wrapper {
            pointer-events: all;
            cursor: pointer;
            transition: margin-top 0.15s;

            &>div {
                height: 40vh !important;
            }

            &>div:hover {
                z-index: 1;

                img {
                    scale: 1.25;
                }
            }

            &.selected:hover img {
                scale: 1 !important;
                translate: 0 -5vh;
                //box-shadow: 0 0 0.6vh 0.6vh var(--main-color);
            }

            img {
                border-radius: 2vh;
                pointer-events: none;
            }
        }
    }
}