.navbar {
    //background-color: var(--bs-body-bg-secondary) !important;
    z-index: 100;
    position: absolute;
    background-color: transparent !important;
    width: 100%;

    a {
        font-weight: 600;
        color: rgba(255, 255, 255, 0.8);
    }

    .navbar-brand a {
        color: var(--main-color) !important;
        font-weight: 800;
    }

    .form-select {
        background-color: var(--bs-body-bg-secondary);
    }

    .current-page {
        font-weight: 700;
        color: white;
    }

    .form-select:disabled {
        opacity: 0.5;
    }

    .tuto-message {
        &>div {
            --size: 1rem;
            background-color: var(--main-color);
            width: var(--size);
            height: var(--size);
            top: calc(var(--size) / -2);
            left: 1rem;
            rotate: 45deg;
        }

        background-color: var(--main-color);
        padding: 0.75rem 1.75rem;
        border-radius: var(--bs-border-radius);
        font-weight: 600;
        color: white;
        margin-top: 2vh;
    }

    .navbar-nav {
        a:has(img) {
            position: relative;
            --img-size: 1.5rem;
            padding-left: calc(var(--img-size) * 1.2) !important;
            img {
                height: var(--img-size);
                position: absolute;
                left: 0;
                opacity: 0.8;
            }
        }
    }

    a:hover {
        color: white;
        text-decoration: underline;

        img {
            opacity: 1 !important;
        }
    }
}

.below-navbar {
    padding-top: 4rem;
}