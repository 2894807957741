.discard {
    bottom: calc(9vh - var(--card-height));
    right: 0.5vh;
    background-color: rgba(0, 0, 0, 0.292);
    width: var(--card-width);
    height: var(--card-height);
    border-radius: 1vh;

    .interaction-zone {
        z-index: 999;
    }
}

.player-section:has(.game-card.dragged) .discard .interaction-zone,
.player-section:has(.game-card.dragged) .deck .interaction-zone {
    display: none;
}