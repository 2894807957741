.stack {
    position: absolute;
    right: -32vh;
    top: -50%;
    height: 40vh;
    width: calc(40vh * 0.7);


    .buttons {
        bottom: -5vh;
        z-index: 1;
    }
}