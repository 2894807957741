.card-search {
    min-height: 85vh;

    .card-size-4 {
        .card-face {
            border-radius: 1.6vh !important;
        }
    }

    .card-search-result-card {
        margin: 0.5vh;
        height: calc(100% - 1vh);

        &.clicked {
            animation: add-card 0.3s ease-in-out;
        }

        @keyframes add-card {
            0% {
                scale: 0.85;
            }

            100% {
                scale: 1;
            }
        }

        .card-face {
            border-radius: 1vh;
            overflow: hidden;
            box-shadow: 0 0 0.5vh 0.25vh rgba(0, 0, 0, 0.358);
            transition: all 0.2s, border-radius 0s;
            outline: 0.5vh solid transparent;

            cursor: pointer;

            &:hover {
                scale: 0.95;
                outline: 0.5vh solid var(--main-color);
            }

            &>div {
                opacity: 0;
                background-color: rgba(0, 0, 0, 0.6);

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

.form-floating {
    &>.form-control {
        padding-top: 1.625rem;
        padding-bottom: .625rem;
    }

    label {
        text-transform: capitalize;
        color: rgba(var(--bs-body-color-rgb), .65);
        transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    }
}

.multi-filter {
    height: 100%;
    display: flex;
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
}