.homepage,
.games,
.top-section {
    .background-color {
        opacity: 0.6;
        background-color: var(--bs-body-bg);
    }
}

.homepage {
    .welcome {
        background: url('./Assets/background.jpg');
        background-size: cover;
        background-position: center;

        .background {
            //backdrop-filter: blur(4px);
        }

        h1 {
            font-weight: 900;

            b {
                color: var(--main-color);
            }
        }

        h2 {
            font-weight: 300;
        }
    }

    .cards {
        max-height: 80rem;

        &.just-appeared {
            img {
                transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) !important;
                opacity: 0.0001 !important;
                scale: 1.5 !important;
            }
        }

        &>div {
            height: 14%;
            position: relative;
            perspective: 200vh;
            width: 90%;

            img {
                height: 100%;
                border-radius: 0.4rem;
                object-fit: contain;
                position: absolute;
                top: 0;
                scale: 2.8;
                filter: drop-shadow(0 0 2vh rgba(0, 0, 0, 0.967));
                transition: transform 1s, opacity 1s, scale 1s, left 0s, right 0s;
                opacity: 1;
            }

            --border-spacing: 18%;

            &:nth-child(1) img {
                transform: rotateX(10deg) rotateY(30deg) rotateZ(10deg);
                transition-delay: 0.2s;
                left: var(--border-spacing);
            }

            &:nth-child(2) img {
                transform: rotateX(30deg) rotateY(-25deg) rotateZ(15deg);
                transition-delay: 0.15s;
                right: var(--border-spacing);
            }

            &:nth-child(3) img {
                transform: rotateX(30deg) rotateY(25deg) rotateZ(-10deg);
                transition-delay: 0.1s;
            }

            &:nth-child(4) img {
                transform: rotateX(15deg) rotateY(-30deg) rotateZ(10deg);
                transition-delay: 0.05s;
                left: var(--border-spacing);
            }

            &:nth-child(5) img {
                transform: rotateX(30deg) rotateY(30deg) rotateZ(0deg);
                right: var(--border-spacing);
            }
        }
    }

    .features {
        background-color: var(--app-bg);

        .feature-image {
            object-fit: contain;
            width: 30rem;
            border-radius: var(--bs-border-radius);
            box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
        }
    }

    @media (orientation: portrait) {
        & {
            .welcome {
                background-position: 35% 50%;
            }

            .welcome>.container {
                flex-direction: column;

                &>div {
                    width: 100% !important;
                }
            }

            .features {
                .box-container {
                    width: 100% !important;
                    flex-direction: column !important;

                    .w-50 {
                        width: 100% !important;
                        margin: 0 !important;

                        .feature-image {
                            width: 100% !important;
                            translate: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

h1 {
    font-weight: 900;
}

.showcased-game {
    border-radius: var(--bs-border-radius);
    overflow: hidden;
    background-color: var(--bs-body-bg-secondary);

    &>img {
        height: 12rem;
        object-fit: cover;
    }

    p {
        margin-bottom: 0;
    }

    h3 {
        font-weight: 700;
    }

    button, a {
        margin-top: 1rem;
    }

    .gradient {
        --size: 2rem;
        top: calc(var(--size) * -1);
        background: linear-gradient(to bottom, transparent, var(--bs-body-bg-secondary));
        height: var(--size);
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.more-games {
    background-color: var(--app-color);
}

.games .background,
.top-section .background-image {
    background-size: cover;
    background-position: 50% 10%;
}