.deck-builder {
    --card-flip-duration: 0.15s;
    --total-duration: calc(var(--card-flip-duration) * 2);

    .focused-card {
        transition: rotate var(--total-duration),
            translate var(--card-flip-duration);
        
        &.flipping {
            transition: rotate var(--total-duration),
            translate var(--card-flip-duration),
            top var(--total-duration);
        }

        .card-front,
        .card-back {
            transition: transform var(--card-flip-duration);
        }

        &.show-back {
            .card-front {
                transform: rotateY(90deg);
                transition-delay: 0s;
            }

            .card-back {
                transform: rotateY(0deg);
                transition-delay: var(--card-flip-duration);
            }
        }

        .card-front {
            transform: rotateY(0deg);
            transition-delay: var(--card-flip-duration);
        }

        .card-back {
            transform: rotateY(-90deg);
            transition-delay: 0s;
        }



        width: 35vh;
        height: 50vh;
        z-index: 10000;
        translate: 0% 0%;
        pointer-events: none;
        padding: 0;

        img {
            box-shadow: 0 0 2vh 1vh rgba(0, 0, 0, 0.509);
            border-radius: 2vh;
        }

        &.horizontal {
            rotate: 90deg;
            translate: 20% -18%;
        }

        &.decklist {
            translate: -13% 0%;

            &.horizontal {
                translate: -35% -18%;
            }
        }

        .flip-indicator {
            left: 0%;
            top: 50%;
            font-size: 2rem;
            font-weight: 600;
            background-color: var(--app-bg);
            width: 3rem;
            border-radius: var(--bs-border-radius);
            transition: all var(--total-duration);
        }
    
        &.horizontal .flip-indicator {
            rotate: -90deg;
            left: 50%;
            top: 90%;
        }
    }

    .form-floating label {
        color: rgba(var(--bs-body-color-rgb), .65) !important;
        transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    }
}