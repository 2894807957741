.history {
    z-index: 1;
    .background {
        background-color: var(--bs-body-bg);
        border-top-left-radius: var(--bs-border-radius);
        opacity: 0.8;
    }

    .blur {
        backdrop-filter: blur(10px);
        border-top-left-radius: var(--bs-border-radius);
        overflow: hidden;
    }

    border-top-left-radius: var(--bs-border-radius);

    width: 30vh;
    height: 20vh;

    .content {
        padding: 1vh 0;

        p {
            font-size: 0.8rem;
            margin: 0.2vh 0;
            margin: 0 1vh;
        }

        p.full-width {
            margin: 1vh 0 2vh 0;
            padding: 0.5vh 0.25vh;
            background-color: var(--bs-body-bg);

            &.error {
                background-color: rgba(163, 42, 42, 0.879);
            }
        }
    }

    .history-focused-card {
        top: -41vh;
        max-height: 43vh;
        width: calc(100% - 1vh);
        right: 0.5vh;

        &.horizontal {
            top: -36vh;
            rotate: 90deg;
            translate: -20%;
        }

        img {
            object-fit: contain;
            border-radius: 1.5vh;
        }
    }

    .history-card-name:hover {
        text-decoration: underline;
    }
}