.hand-board-section {
    height: calc(var(--card-height) - 7vh);
    width: 50vw !important;
    position: absolute;
    bottom: 0;
    left: calc(50% - 25vw);

    .hand {
        height: 100%;
    }
}

.opponents.total-2 .unzoomed .hand-board-section, .opponents.total-3 .unzoomed .hand-board-section {
    bottom: -15vh;
}